import React from 'react';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { useAutoSuggestions } from '../../hooks/useAutoSuggestions';
import { getAutoSuggestSearchSuggestions, getAutoSuggestOriginalQuery } from '../../utils/autoSuggestResults';
import { type IAutoSuggestSearchSuggestions } from '../../types/autoSuggestTypes';
import { isEmpty } from '../../utils/utilities';
import { useTranslation } from 'react-i18next';
import styles from './ProductSuggestions.module.scss';
import { createUrl, productUrl } from '../../utils/appConfig';

export const ProductSuggestions = (): JSX.Element | null => {
  // Get translations for component text.
  const { t } = useTranslation('common');

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  const {
    isSuccess,
    data
  } = useAutoSuggestions(appConfig);

  if (!isSuccess) {
    return null;
  }

  if (isEmpty(data)) {
    return null;
  }

  const suggestions: IAutoSuggestSearchSuggestions[] = getAutoSuggestSearchSuggestions(data);
  const originalQuery = getAutoSuggestOriginalQuery(data);

  if (isEmpty(suggestions)) {
    return null;
  }

  return (
    <>
        <ul className={styles.productList}>
          {
            suggestions.map((suggestion: IAutoSuggestSearchSuggestions) => {
              return (
                <li key={suggestion.pid} className={styles.productItem}>
                  <a href={productUrl(suggestion.url)}>
                    <div className={styles.imgWrapper}>
                      <img src={suggestion.thumb_image} alt={suggestion.title}/>
                    </div>
                    <span>{suggestion.title}</span>
                  </a>
                </li>
              );
            })
          }
        </ul>
        <a className={styles.btn} href={createUrl(appConfig.search_page_url + '?q=' + originalQuery)}><span>{t('search.product_suggestions_cta')}</span></a>
    </>
  );
};

export default ProductSuggestions;
