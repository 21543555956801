import React, { useEffect, useState } from 'react';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import styles from './SpecialOffers.module.scss';
import { createUrl } from '../../utils/appConfig';

export const SpecialOffers = (): JSX.Element | null => {
  const [offerDescription, setOfferDescription] = useState('See all Black Friday offers');
  const [offerDescriptionDetails, setOfferDescriptionDetails] = useState(' | Use discount code');

  // Get the app config state.
  const {
    appConfig
  } = useAppConfigContext();

  // Set text language
  useEffect(() => {
    if (appConfig.current_language === 'de-de') {
      setOfferDescription('5% Rabatt auf alle Tickets');
      setOfferDescriptionDetails(' | Rabattcode');
    } else if (appConfig.current_language !== 'en') {
      setOfferDescription('5% off sitewide');
    }
  }, [appConfig.current_language]);

  return (
      <>
        <div className={`${styles.specialOffersContainer} specialOffersContainer ` +
        (appConfig.current_language === 'en' ? 'styles.specialOffersContainerEn' : '') + ' ' +
        (appConfig.current_language === 'de-de' ? 'styles.specialOffersContainerDe' : '')}>
          <a href={createUrl('node/235203')} className={styles.specialOffersLink} >
            <div className={styles.specialOffers}>
              {appConfig.current_language !== 'en'
                ? <div>
                    <strong className={styles.offerDescription}>
                      {offerDescription}
                      <span>{offerDescriptionDetails}</span>
                    </strong>
                    <span className={styles.offerCode}>BLACKFRIDAY</span>
                  </div>
                : <strong className={styles.offerDescription}>
                    {offerDescription}
                  </strong>
              }
            </div>
          </a>
        </div>
      </>
  );
};

export default SpecialOffers;
